import React from "react";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import p1 from "../630cb940c5c4fa57353cf11f_feature-icon-06.svg.png";
import p2 from "../bb.png";
import p3 from "../cc.png";
import "./map.css";
import { useTranslation } from "react-i18next";

const MapsBranch = () => {
  const { t } = useTranslation();
  return (
    <div className="px-5">
      <section className="container mx-auto flex gap-[20px] flex-col xl:flex-row pb-6 xl:items-start">
        <div className="flex justify-center items-baseline h-auto">
          <YMaps>
            <Map
              className="overflow-hidden shadow-xl rounded-2xl w-[728px] xs2: container mx-auto  h-[400px]  lg:h-[444px]"
              defaultState={{
                center: [38.563697, 68.758866],
                zoom: 19.31,
                controls: ["zoomControl", "fullscreenControl", "typeSelector"],
                behaviors: ["default", "scrollZoom"],
              }}
              modules={[
                "control.ZoomControl",
                "control.FullscreenControl",
                "control.TypeSelector",
              ]}
            >
              <Placemark defaultGeometry={[38.563697, 68.758866]} />
            </Map>
          </YMaps>
        </div>

        <div>
          <div>
            <p className=" text-[28px]  font-[700] dark:text-[#FAFAFAFA]  text-[#262626]">
              Контакты
            </p>
          </div>
          <div>
            <div className=" mt-[20px] w-full container  gap-[10px] flex items-start p-[18px] sm:p-[24px] rounded-[20px] bg-white dark:bg-[#161F2D] sm:h-[120px]">
              <img
                className=" w-[60px] h-[60px] rounded-full"
                src={p1}
                alt="rec"
              />
              <div>
                <p className=" text-[20px] font-[600] dark:text-[#FAFAFA] text-[#262626]">
                  Телефон
                </p>
                <p className=" text-[16px] font-[500] dark:text-[#FAFAFA] text-[#525252]">
                  +992 558 700 900
                </p>
              </div>
            </div>
            <div className=" mt-[20px] gap-[10px] flex items-start p-[18px] sm:p-[24px] rounded-[20px] bg-white dark:bg-[#161F2D]   sm:h-[120px]">
              <img
                className="w-[60px] h-[60px]  rounded-full"
                src={p2}
                alt="rec1"
              />
              <div>
                <p className=" text-[20px] font-[600] dark:text-[#FAFAFA] text-[#262626]">
                  Адрес
                </p>
                <p className=" sm:w-[260px] xs2:text-[16px] text-[13px] dark:text-[#FAFAFA] font-[500] text-[#525252]">
                  {t("footer.address")}
                </p>
              </div>
            </div>
            <div className=" mt-[20px] gap-[10px] flex items-start p-[18px] sm:p-[24px] rounded-[20px] bg-white dark:bg-[#161F2D]   sm:h-[120px]">
              <img
                className="w-[60px] h-[60px]  rounded-full"
                src={p3}
                alt="pol"
              />
              <div>
                <p className=" text-[20px] dark:text-[#FAFAFA] font-[600] text-[#262626]">
                  E-mail
                </p>
                <p className=" xs2:text-[16px] dark:text-[#FAFAFA] text-[13px] break-words font-[500] text-[#525252]">
                  softclubacademy@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MapsBranch;
