import { Button } from "antd";
import React from "react";
import LeftOutlined from "../../Components/swiperSlider/IconLeft";
import RightOutlined from "../../Components/swiperSlider/IconRightOutlined";

const BlogsButton = ({ previous, next }) => {
  return (
    <div className="hidden md:flex gap-4">
      <Button
        className="w-[48px] h-[48px] rounded-[10px] dark:bg-[#334155] bg-white text-red-300"
        onClick={previous}
      >
        <LeftOutlined
          fill={"#0284C7"}
          svgStyle={{ display: "inline-block", verticalAlign: "middle" }}
        />
      </Button>
      <Button
        className="w-[48px] h-[48px] rounded-[10px] dark:bg-[#334155] bg-white"
        onClick={next}
      >
        <RightOutlined
          fill={"#0284C7"}
          svgStyle={{ display: "inline-block", verticalAlign: "middle" }}
        />
      </Button>
    </div>
  );
};

export default BlogsButton;
