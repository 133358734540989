import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useOutletContext, useParams } from "react-router-dom";
import { useGetCourseByIdQuery } from "../../api/course";
import { useGetAllSyllabusesQuery } from "../../api/syllabus";
import ExperimentalSwiper from "../../Components/swiperSlider/ExperimentalSwiper";
import MapsBranch from "../Home/components/MapsBranch";
import StudentsReview from "../Reviews/studentReviews";
import CourseDuration from "./components/CourseDuration";
import CourseIntroduction from "./components/CourseIntroduction";
import CourseProgramm from "./components/CourseProgramm";
import CourseSalaries from "./components/CourseSalaries";
import CourseSkills from "./components/CourseSkills";
import CourseTrainings from "./components/CourseTrainings";

const NewCoursePage = () => {
  const { id } = useParams();
  const [dark, lang] = useOutletContext();
  const { data, isLoading } = useGetCourseByIdQuery({ id, lang });
  const { data: syllabus } = useGetAllSyllabusesQuery({ lang, id });

  return isLoading ? (
    <div>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center ",
        }}
      >
        <CircularProgress />
      </Box>
    </div>
  ) : (
    <main className="pt-[100px] md:pt-[100px]">
      <Helmet>
        <title>{`Softclub ${data?.data?.title} course`}</title>
        <meta
          name="description"
          content={data?.data?.subTitle}
          data-react-helmet="true"
        />
        <meta
          property="keywords"
          name="keywords"
          content="программирование, онлайн курсы, веб, Front-end, Back-end, Olympiad, C++ , JS , React , Phython, быстро научись программировать ,бесплатные курсы программирования в душанбе"
          data-react-helmet="true"
        />
      </Helmet> 
      <CourseIntroduction
        title={data?.data?.title}
        logo={data?.data?.logo}
        subTitle={data?.data?.subTitle}
      />
      <CourseDuration
        startDate={data?.data?.startDate}
        duration={data?.data?.duration}
        durationType={data?.data?.durationType}
        teachingFormat={data?.data?.teachingFormat}
        neededStudents={data?.data?.neededStudents}
        description={data?.data?.description}
        dark={dark}
      />
      <CourseSkills dark={dark} courseSkills={data?.data?.courseSkills} />
      <CourseSalaries />
      <section>
        <div className="container mx-auto mt-[80px] mb-[40px]">
          <StudentsReview />
        </div>
      </section>
      <CourseTrainings />
      <CourseProgramm
        dark={dark}
        syllabus={syllabus?.data}
        teachers={data?.data?.teachers}
      />
      <section className="my-[80px]">
        <ExperimentalSwiper
          slideKey={"studentReviews"}
          homeKey={"studentReviews"}
        />
      </section>
      <div className="mt-10">
        <MapsBranch />
      </div>
    </main>
  );
};

export default NewCoursePage;
