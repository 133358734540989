import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import i18n from "i18next";
import { useState } from "react";
import rus from "../../images/rus.png";
import taj from "../../images/tjk.png";
import eng from "../../images/eng.png";
import "./language.css";

// setLang props is for RTK query
function Language({ setLang }) {
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  let [language, setLanguage] = useState("rus");

  const handleChange = (event) => {
    const { value } = event.target;
    changeLanguage(value);
    setLanguage(value);
    setLang(value);
    localStorage.setItem("i18nextLng", value || "rus");
  };

  useEffect(() => {
    setLanguage(localStorage.getItem("i18nextLng") || "rus");
  }, [language]);

  return (
    <>
      <FormControl
        variant="standard"
        sx={{
          width: {
            xs: "80%", // Для экранов маленького размера (xs и выше)
            md: "100%", // Для экранов большого размера (lg и выше)
          },
        }}
        size="small"
      >
        <Select
          className="dark:text-sky-400 border  rounded-[10px] py-3 px-5 border-solid "
          sx={{
            ".css-qq14pi.css-qq14pi.css-qq14pi": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            },
            color: "#336699",
            ".css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon": {
              display: "none",
            },
            ".css-yym4go-MuiInputBase-root-MuiInput-root-MuiSelect-root": {
              bgcolor: "none",
            },
            ".css-1umn0yb-MuiInputBase-root-MuiInput-root-MuiSelect-root": {
              minWidth: "80%",
            },
            ".css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input":
              {
                display: "flex !important",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
              },
          }}
          labelId="demo-select-small"
          id="demo-select-small"
          value={language}
          label="language"
          onChange={handleChange}
          disableUnderline
        >
          <MenuItem
            value="rus"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <img width={24} src={`${rus}`} alt="ru" />{" "}
            <span className="text-[#262626] dark:text-white font-semibold">
              RU
            </span>
          </MenuItem>
          <MenuItem
            value="eng"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <img width={24} src={`${eng}`} alt="en" />{" "}
            <span className="text-[#262626] dark:text-white font-semibold">
              EN
            </span>
          </MenuItem>
          <MenuItem
            value="taj"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <img width={24} src={`${taj}`} alt="tj" />{" "}
            <span className="text-[#262626] dark:text-white font-semibold">
              TJ
            </span>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}

export default Language;
