import Accordion from "@mui/joy/Accordion";
import AccordionDetails, {
  accordionDetailsClasses,
} from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import * as React from "react";
import Sign from "../../../Components/Sign/Sign";

export default function CourseProgramm({ syllabus, teachers, dark }) {
  return (
    <>
      {syllabus?.length !== 0 && (
        <section className="container  px-2 mx-auto my-[80px]">
          <div>
            <h1 className="text-[28px] dark:text-white font-bold text-[#262626] mb-6">
              Программа курса
            </h1>
          </div>
          <AccordionGroup
            variant="outlined"
            transition="0.2s"
            sx={{
              borderRadius: "lg",
              bgcolor: dark === "dark" ? "#FFFFFF" : "#161F2D",
              border: "none",
              [`& .${accordionSummaryClasses.button}:hover`]: {
                bgcolor: "inherit",
              },
              [`& .${accordionDetailsClasses.content}`]: {
                boxShadow: (theme) =>
                  `inset 0 1px ${theme.vars.palette.divider}`,
                [`&.${accordionDetailsClasses.expanded}`]: {
                  paddingBlock: "0.75rem",
                  color: dark === "dark" ? '"#0284C7"' : "white",
                },
              },
              ".css-n5mis1-JoyAccordionGroup-root": {
                border: "none",
              },
              ".MuiAccordionSummary-root .css-1twr7re-JoyAccordionSummary-button":
                {
                  color: dark === "dark" ? '"#0284C7"' : "white",
                },
            }}
          >
            {syllabus?.length !== 0 &&
              syllabus?.map((course, i) => (
                <Accordion
                  key={course?.id}
                  sx={{ px: "40px", py: "20px" }}
                  defaultExpanded={i === 0}
                >
                  <AccordionSummary
                    sx={{
                      color: dark === "dark" ? "#262626" : "white",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      ".css-1r2bn3-JoySvgIcon-root": {
                        color: "#0284C7",
                        bgcolor: dark !== "dark" ? "#092B3E" : "#E0F2FE",
                        borderRadius: "10px",
                      },
                    }}
                  >
                    {course?.title}
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      color: dark === "dark" ? '"#0284C7"' : "white",
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {course?.description}
                  </AccordionDetails>
                </Accordion>
              ))}
          </AccordionGroup>
        </section>
      )}
      {teachers.length !== 0 && (
        <section className="container px-2 mx-auto">
          <div>
            <h1 className="text-[28px] font-bold text-[#262626] dark:text-white mb-6">
              Преподаватели
            </h1>
            <main className="flex items-center justify-between flex-wrap gap-4">
              {teachers?.map((teacher) => (
                <div
                  key={teacher.id}
                  className="bg-white dark:bg-[#161F2D] py-6 px-7 flex flex-col sm:flex-row rounded-2xl items-start gap-7 justify-between max-w-[550px]"
                >
                  <div className="flex flex-col items-start justify-between">
                    <img
                      className="w-[140px] h-[140px] object-contain rounded-full"
                      src={`${process.env.REACT_APP_ASSETS_URL}${teacher?.photo}`}
                      alt={teacher?.fullName}
                    />
                    <div>
                      <h2 className=" xs2:text-[16px] text-center md:text-xl font-bold dark:text-[#FAFAFA] text-gray-900">
                        {teacher?.fullName}
                      </h2>
                    </div>
                  </div>
                  <div className="flex flex-col items-start sm:items-end justify-between">
                    <h1
                      dangerouslySetInnerHTML={{ __html: teacher?.about }}
                      className="text-trueGray700 text-start break-words  dark:text-trueGray200 text-[14px] font-[500] mb-[5px]"
                    />
                    <div className="flex items-center justify-start sm:justify-end  gap-2 mt-4">
                      {teacher?.teacherSkillsDtos?.map((skill, index) => (
                        <img
                          key={index}
                          src={`${process.env.REACT_APP_ASSETS_URL}${
                            skill?.skillImage || "public/logo192.png"
                          }`}
                          alt={`name${index}`}
                          className="h-10"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </main>
          </div>
        </section>
      )}
      <section className="container mx-auto mt-20">
        <Sign />
      </section>
    </>
  );
}
