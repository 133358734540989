import { Instagram, Telegram, WhatsApp } from "@mui/icons-material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import { Container, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.svg";

function Info({ dark }) {
  const { t } = useTranslation();

  const [isActive, setIsActive] = React.useState(false);

  return (
    <footer className="bg-[#F8FAFC] dark:bg-[#0F172A]  font-main py-8">
      <Container>
        <Grid container spacing={4} mt={1}>
          <Grid item xs={12} sm={5} md={5} lg={5}>
            <img
              src={logo}
              alt="SoftClub"
              className="h-[64px] w-[141px] mb-4"
            />
            <Typography
              component={"h3"}
              sx={{
                fontSize: "20px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                lineHeight: "28px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
            >
              {t("footer.text2")}
            </Typography>
          </Grid>
          <Grid item xs={0} sm={10} md={2} lg={1}></Grid>
          <Grid
            item
            xs={12}
            sm={4}
            lg={3}
            sx={{
              borderRight: {
                lg: "1px solid #E2E8F0",
                xs: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                lineHeight: "28px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
              gutterBottom
            >
              <div className="h-[1px] lg:hidden bg-[#E2E8F0] mb-3"></div>
              {t("footer.header")}
            </Typography>
            <ul className="list-none text-[#262626] dark:text-[#FAFAFAFA] p-0 text-[16px] font-[500]">
              <li
                className="cursor-pointer"
                onClick={() => {
                  window.scroll({
                    top: 5700,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h1>{t("home.header.btn-more")}</h1>
              </li>
              <li
                className="cursor-pointer"
                onClick={() => {
                  window.scroll({
                    top: 3900,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <h1>{t("home.courses.header")}</h1>
              </li>
              <li className="cursor-pointer">
                <h1>{t("footer.blogs")}</h1>
              </li>
              <li className="cursor-pointer">
                <h1>{t("footer.about-us")}</h1>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className="h-[1px] lg:hidden bg-[#E2E8F0] mb-3"></div>
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                lineHeight: "28px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
              gutterBottom
            >
              Контакты
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                lineHeight: "24px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
            >
              info@softclub.tj
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                lineHeight: "24px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
            >
              +992 558 700 900
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                lineHeight: "24px",
                color: dark === "dark" ? "#262626" : "#FAFAFA",
              }}
            >
              {t("footer.address")}
            </Typography>
            <div className="flex items-center mt-4">
              <IconButton
                href="https://www.facebook.com/infosoftclub.tj/"
                className="text-blue-500"
              >
                <FacebookRoundedIcon sx={{ color: "#075985" }} />
              </IconButton>
              <IconButton
                href="https://t.me/softclubtj"
                className="text-blue-400"
              >
                <Telegram sx={{ color: "#075985" }} />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/softclub.tj/"
                className="text-pink-500"
              >
                <Instagram sx={{ color: "#075985" }} />
              </IconButton>
              <IconButton href="https://softclub.tj" className="text-red-500">
                <WhatsApp sx={{ color: "#075985" }} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Container>

      <nav
        style={{ boxShadow: "0px -2px 3px 0px #0000000D" }}
        className="bg-white dark:bg-[#0F172A]  fixed z-20 w-full bottom-0 py-[10px] sm:hidden"
      >
        <ul className="flex items-center justify-between">
          <NavLink
            to="/"
            onClick={() => {
              window.scroll({
                top: -30,
                left: 0,
                behavior: "smooth",
              });
              setIsActive(false);
            }}
            className={({ isActive }) =>
              isActive
                ? `text-[#0284C7] flex flex-col container items-center`
                : `flex flex-col hover:text-[#0284C7] container text-[#64748B] items-center`
            }
          >
            <span>
              <HomeRoundedIcon fontSize="large" />
            </span>
            <span className="text-[12px] font-[500]">Главная</span>
          </NavLink>{" "}
          <NavLink
            to="/"
            onClick={() => {
              window.scroll({
                top: 6050,
                left: 0,
                behavior: "smooth",
              });
              setIsActive(false);
            }}
            className={() =>
              `flex flex-col hover:text-[#0284C7] container text-[#64748B] items-center`
            }
          >
            <span>
              <GridViewRoundedIcon fontSize="large" />
            </span>
            <span className="text-[12px] font-[500]">Курсы</span>
          </NavLink>{" "}
          <NavLink
            to="/Blogs"
            onClick={() => {
              window.scroll({
                top: -20,
                left: 0,
                behavior: "smooth",
              });
              setIsActive(false);
            }}
            className={({ isActive }) =>
              isActive
                ? `text-[#0284C7] flex flex-col container items-center`
                : `flex flex-col hover:text-[#0284C7] container text-[#64748B] items-center`
            }
          >
            <span>
              <LayersRoundedIcon fontSize="large" />
            </span>
            <span className="text-[12px] font-[500]">Новости</span>
          </NavLink>{" "}
          <NavLink
            to="/AboutUs"
            onClick={() => {
              window.scroll({
                top: -20,
                left: 0,
                behavior: "smooth",
              });
              setIsActive(true);
            }}
            onMouseEnter={() => {
              setIsActive(true);
            }}
            onMouseLeave={() => {
              setIsActive(false);
            }}
            className={({ isActive }) =>
              isActive
                ? `text-[#0284C7] flex flex-col container items-center`
                : `flex flex-col hover:text-[#0284C7] container text-[#64748B] items-center`
            }
          >
            <span className="hover:text-[#0284C7]">
              <svg
                className="w-[35px] h-[35px] text-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.2933 1.79999L6.3 5.56363V5.92487H7.77518L11.4067 7.29252L17.7 4.92228V1.79999H16.2933ZM12.5934 12.1502L6.3 14.5204V14.8816H7.77518L11.4067 16.2492L17.7 13.879V13.5968H16.4344L12.5934 12.1502ZM17.7 18.9987L7.7067 15.2351H6.3V18.3574L16.5029 22.2H17.7V18.9987ZM17.7 10.0419L7.7067 6.27835H6.3V9.40064L16.5029 13.2433H17.7V10.0419Z"
                  fill={isActive ? "#0284C7" : "#64748B"}
                />
              </svg>
            </span>
            <span className="text-[12px] font-[500]">О нас</span>
          </NavLink>
        </ul>
      </nav>
    </footer>
  );
}

export default Info;
